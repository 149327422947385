import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import styled from "styled-components";
import ContentWrapper from "../components/ContentWrapper";
import { graphql, Link } from "gatsby";

const HeroWrapper = styled.div`
  position: relative;
  top: 65px;
  left: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #000;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 1385px) {
    display: flex;
    justify-content: center;
  }
  video {
    width: 100%;
    @media screen and (max-width: 1385px) {
      width: 1385px;
      height: 779px;
    }
  }
`;

const NewsWrapper = styled.div`
  background: #e6e6ee;
  padding: 80px 0;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding: 50px 0;
  }
  .news-container {
    text-align: center;
  }
  .news-layout {
    height: 550px;
    display: flex;
    align-content: stretch;
    background: #fff;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      height: auto;
      display: block;
    }
  }
  .news-layout-item {
    position: relative;
    &:first-child {
      padding: 0 45px;
      @media screen and (max-width: ${props => props.theme.responsive.large}) {
        padding: 0;
      }
    }
    &:last-child {
      overflow-y: scroll;
      overflow-x: hidden;
      flex-grow: 1;
    }
  }
  .news-layout-item-inner {
    padding: 225px 0;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      padding: 25px 0;
    }
  }
  .news-layout-item-title {
    font-size: 21px;
    line-height: 1;
    font-weight: bold;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      font-size: 18px;
    }
  }
  .news-button-container {
    margin-top: 30px;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      display: none;
    }
  }
  .news-button {
    display: inline-block;
    padding: 15px 17px;
    border: 2px solid #c7c7ce;
    border-radius: 25px;
    font-size: 1rem;
    line-height: 1;
    font-weight: bold;
    letter-spacing: normal;
    min-width: 340px;
    background: transparent;
    color: #000;
    transition: opacity 0.2s;
    position: relative;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      min-width: 255px;
      font-size: 12px;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 26px;
      width: 9px;
      height: 9px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      transform: rotate(45deg);
      @media screen and (max-width: ${props => props.theme.responsive.large}) {
        right: 22px;
        width: 6px;
        height: 6px;
      }
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .news-list-item {
    &:nth-child(odd) {
      background: #f6f6f9;
    }
  }
  .news-link {
    display: block;
    padding: 15px 145px 15px 30px;
    min-height: 110px;
    text-align: left;
    transition: opacity 0.2s;
    position: relative;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      padding: 10px 55px 15px 20px;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 44px;
      width: 8px;
      height: 8px;
      border-top: 3px solid #818181;
      border-right: 3px solid #818181;
      transform: rotate(45deg);
      @media screen and (max-width: ${props => props.theme.responsive.large}) {
        right: 23px;
        width: 6px;
        height: 6px;
        border-top: 2px solid #818181;
        border-right: 2px solid #818181;
      }
    }
    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }
  }
  .news-link-date {
    font-size: 13px;
    color: #5b5b5b;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      display: block;
      font-size: 12px;
    }
  }
  .news-link-text {
    font-size: 14px;
    color: #000;
    margin-top: 2px;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      font-size: 12px;
    }
  }
  .news-button-container-responsive {
    display: none;
    margin: 25px auto;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      display: block;
    }
  }
`;

const CareersWrapper = styled.div`
  background-image: url(/images/bg-careers.jpg);
  background-size: cover;
  background-position: center;
  padding: 240px 0;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    padding: 25% 0 80px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding: 20.8333% 0;
  }
  .careers-wrapper {
    padding-left: 4.16%;
    padding-right: 4.16%;
  }
  .careers-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .careers-heading {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .careers-heading-title {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    @media screen and (max-width: ${props => props.theme.responsive.small}) {
      font-size: 1rem;
    }
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      font-size: 1.125rem;
    }
  }
  .careers-heading-line {
    background: #fff;
    border-radius: 5px;
    width: 28px;
    height: 4px;
    margin-top: 12px;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      margin-top: 8px;
    }
  }
  .careers-description {
    color: #fff;
    text-align: center;
    font-size: 1rem;
    line-height: 1.8;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      font-size: 0.875rem;
    }
  }
  .careers-button {
    text-align: center;
    line-height: 1;
    padding: 18px 16px;
    transition: all 0.3s ease-in-out 0s;
    font-size: 0.75rem;
    width: 144px;
    margin-top: 40px;
    background: #fff;
    color: #2b546a;
    letter-spacing: 0.02em;
    font-weight: bold;
    border-radius: 5px;
    @media screen and (max-width: ${props => props.theme.responsive.small}) {
      margin-top: 24px;
    }
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      margin-top: 32px;
    }
    &:hover {
      background: #f2f2f2;
    }
  }
`;

class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMicrocmsNews.edges;

    return (
      <Layout>
        <Seo title="" />
        <Helmet>
          <link rel="canonical" href="https://yagizouen.co.jp" />
        </Helmet>
        <HeroWrapper>
          <video loop preload="auto" playsInline autoPlay muted>
            <source src="/video/hero.mp4" />
            Your browser does not support HTML5 video.
          </video>
        </HeroWrapper>
        <NewsWrapper>
          <div className="news-container">
            <ContentWrapper>
              <section className="news-layout">
                <div className="news-layout-item">
                  <div className="news-layout-item-inner">
                    <h3 className="news-layout-item-title">ニュース</h3>
                    <div className="news-button-container">
                      <Link to={`/news`} className="news-button">一覧をみる</Link>
                    </div>
                  </div>
                </div>
                <div className="news-layout-item">
                  <ul>
                    {posts.map(({ node }) => {
                      return (
                        <li key={node.newsId} className="news-list-item">
                          <Link to={`/news/${node.newsId}`} className="news-link">
                            <time className="news-link-date">{node.createdAt}</time>
                            <p className="news-link-text">{node.title}</p>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="news-button-container-responsive">
                    <Link to={`/news`} className="news-button">一覧をみる</Link>
                  </div>
                </div>
              </section>
            </ContentWrapper>
          </div>
        </NewsWrapper>
        <CareersWrapper>
          <div className="careers-wrapper">
            <ContentWrapper>
              <div className="careers-inner">
                <div className="careers-heading">
                  <h3 className="careers-heading-title">八木造園土木で一緒に働きませんか？</h3>
                  <div className="careers-heading-line"></div>
                </div>
                <p className="careers-description">八木造園土木では、"ミライ"を形にする仲間を募集しています。<br />下記リンクより、ぜひご応募ください。</p>
                <a href="https://en-gage.net/yagizouen" target="_blank" rel="noopener noreferrer" className="careers-button">採用情報</a>
              </div>
            </ContentWrapper>
          </div>
        </CareersWrapper>
      </Layout>
    );
  };
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMicrocmsNews(sort: {order: DESC, fields: createdAt}, limit: 10) {
      edges {
        node {
          createdAt(formatString: "YYYY年MM月DD日", locale: "ja-JP")
          newsId
          title
          body
        }
      }
    }
  }
`;